import Clover from "../assets/images/clover.jpg";
import Lowcostfurnituredirect from "../assets/images/lowcostfurniture.jpg";
import Tepstudio from "../assets/images/tepstudio.jpg";
import MemoryGame from "../assets/images/memoryGame.jpg";
import Travelandculture from "../assets/images/travelandculture.jpg";
import Asuakoworking from "../assets/images/asuakoworking.jpg";
import Mariauranga from "../assets/images/mariauranga.jpg";
import Bobysuh from "../assets/images/bobysuh.jpg";
import Commerce from "../assets/images/commerce.jpg";
import Mail from "../assets/images/mail.jpg";
import Network from "../assets/images/network.jpg";
import DevTree from "../assets/images/devTree.jpg";
import Cashtrackr from "../assets/images/cashTrackr.jpg";


const projects = [
  {
    image: Cashtrackr,
    title: "CashTrackr",
    description:
      "CashTrackr is a user-friendly website for managing expenses, accessible from anywhere and backed by robust security. It is built with a modern tech stack, including React, Next.js, Tailwind, Node.js, Express, TypeScript, PostgreSQL, Sequelize, and Mailjet for email delivery",
    url: "https://cashtrackr-frontend-deploy.vercel.app/",
  },
  {
    image: DevTree,
    title: "DevTree",
    description:
      "I created this website as part of an online course. It's a place where you can gather all your social networks and share them all in one link. In this project, I used React, React Query, Vite, Tailwind, Node.js, Express, TypeScript, MongoDB, Mongoose, and Cloudinary to host the images.",
    url: "https://eric-devtree.netlify.app/",
  },
  {
    image: Network,
    title: "Network",
    description:
      "This is a Threads-like social network website for making posts and following users. I used HTML, CSS, Bootstrap, Javascript, Python, Django, Github and it is hosted on Heroku.",
    url: "https://networkcs50-c6e6a53f09a0.herokuapp.com/",
  },
  {
    image: Mail,
    title: "Mail",
    description:
      "This is a front-end email client that makes API calls to send and receive emails. I used HTML, CSS, Bootstrap, Javascript, Python, Django, Github and it is hosted on Heroku.",
    url: "https://mailcs50-c8455b135c15.herokuapp.com/",
  },
  {
    image: Commerce,
    title: "Commerce",
    description:
      "This is an eBay-like e-commerce auction site that allows users to post auction listings, place bids, comment on listings, and add them to a watchlist. I used HTML, CSS, Bootstrap, Python, the framework Django and it is hosted on Heroku.",
    url: "https://commerce-cs50-b05b33458d44.herokuapp.com/",
  },
  {
    image: Clover,
    title: "Clover",
    description:
      "This website was created to participate in the Code Institute's Hackathon in January. The theme was mental health and I am very proud that my group won second place. We used HTML, CSS, Bootstrap, Javascript and it is hosted on GitHub.",
    url: "https://lexach91.github.io/team-5-january-hackathon/",
  },
  {
    image: Lowcostfurnituredirect,
    title: "Lowcostfurnituredirect",
    description:
      "lowcostfurnituredirect.ie is an online retailer that sells and delivers its products all over Ireland. The website is made with WordPress and WooCommerce. I worked for this company as a freelance web developer; repairing, maintaining and updating all company websites.",
    url: "https://lowcostfurnituredirect.ie/",
  },
  {
    image: Tepstudio,
    title: "Tepstudio",
    description:
      "I co-created this website with a colleague as a contractor for a client who set up a recording studio. We Used WordPress for this project. We created our own template from the backend.",
    url: "https://tepstudio.ie/",
  },
  {
    image: MemoryGame,
    title: "Memory Game",
    description:
      "I built this Memory Game for the Javascript project in the course Fullstack Web Developer in Code Institute. It's fun to play and helps to improve the user's memory. To win you need to match all the pairs before time runs out.",
    url: "https://ericgaona.github.io/InteractiveMilestoneProject/",
  },
  {
    image: Travelandculture,
    title: "travelandculture.ie",
    description:
      "I created this website using WordPress for a client who wanted to set up a travel blog. I advised on the best template and layout that would work best for her vision. We collaborated over a number of tweaks until client was delighted with the result.",
    url: "https://travelandculture.ie/",
  },
  {
    image: Asuakoworking,
    title: "asuakoworking.com",
    description:
      "This website was coded from inception using a mixture of HTML, Bootstrap, CSS, Javascript plus a Google form because the client wanted to collect data. Together with colleagues we use a collaboration software (GitHub) to manage this project.",
    url: "https://www.asuakoworking.com/",
  },
  {
    image: Mariauranga,
    title: "mariauranga.com",
    description:
      "This website is a result of a collaboration between myself and colleagues. We created the website in WordPress and also brought in a woocommerce expert for that particular section.",
    url: "https://mariauranga.com/",
  },
  {
    image: Bobysuh,
    title: "diseñograficobilbao.com",
    description:
      "This website is the final product of a team collaboration using WordPress which I worked on during my time in-house at Bobysuh, a company in Bilbao who supplies tailored website to clients.",
    url: "https://www.xn--diseograficobilbao-q0b.com/",
  },
];

export { projects };
